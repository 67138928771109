import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import StickyNav from "../components/sticky-nav/sticky-nav"
import Footer from "../components/footer/footer"
import { graphql, navigate } from "gatsby"

import {
  productsMain,
  productsPage,
  productCardAction,
  productCardRow,
  productCardImage,
  productCardText,
  productCardTitle,
  productCardDescription,
  productCardButton,
  productCardCompanyLogo,
  productPageHeader,
  productPageHeaderText,
  productPageHeaderImage,
  productCounter,
} from "./producten.module.css"
import { GatsbyImage } from "gatsby-plugin-image"
import RichTextContent from "../components/rich-text-content/rich-text-content"

const ImageComponent = ({ selectedImage }) => {
  if (selectedImage.url) {
    return <img className={productCardImage} src={selectedImage.url} alt="" />
  } else {
    return (
      <GatsbyImage
        layout="fixed"
        className={productCardImage}
        image={selectedImage}
      />
    )
  }
}

const ProductPage = ({ data }) => {
  const navigationDesks = data.navigationDesks.nodes
  const products = data.productList.nodes
  const pageData = data.contentfulPage

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://www.mkbservicedesk.nl/producten/" />
      </Helmet>
      <header className="header">
        <StickyNav desks={navigationDesks} />
      </header>
      <main className={productsPage}>
        {/* Page header */}
        <div className={productPageHeader}>
          <div className={productPageHeaderText}>
            <h2>{pageData.introTitle}</h2>
            <RichTextContent content={pageData.introDescription} />
          </div>
          <GatsbyImage
            layout="fixed"
            className={productPageHeaderImage}
            image={pageData.introImage?.gatsbyImageData}
            alt={pageData.introImage?.title ?? ""}
          />
        </div>
        {/* Page content */}
        <div className={productsMain}>
          <p className={productCounter}>
            Oplossingen <span>({products.length})</span>
          </p>
          {products?.map((product) => {
            const selectedImage =
              product.overviewImage?.gatsbyImageData ||
              product.overviewImage?.file ||
              product.introImage?.gatsbyImageData ||
              product.introImage?.file

            return (
              <div key={product.contentful_id} className={productCardRow}>
                <ImageComponent selectedImage={selectedImage} />
                <div className={productCardText}>
                  <span className={productCardTitle}>{product.name}</span>
                  <p className={productCardDescription}>
                    {product.excerpt?.childMarkdownRemark.rawMarkdownBody ??
                      product.introDescription.childMarkdownRemark
                        .rawMarkdownBody}
                  </p>
                  <div className={productCardAction}>
                    <button
                      className={productCardButton}
                      onClick={() => navigate(`/product/${product.slug}`)}
                    >
                      {product.overviewButtonText ?? "Nu aanvragen"}
                    </button>
                    {product.companyLogo?.gatsbyImageData && (
                      <GatsbyImage
                        layout="fullWidth"
                        className={productCardCompanyLogo}
                        image={product.companyLogo?.gatsbyImageData}
                        alt={product.companyLogo?.title ?? ""}
                      />
                    )}
                    {product.companyLogo?.gatsbyImageData === null &&
                      product.companyLogo?.file && (
                        <img
                          src={product.companyLogo.file.url}
                          alt={product.companyLogo?.title ?? ""}
                        />
                      )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default ProductPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    contentfulPage(slug: { eq: "producten" }) {
      introTitle
      introDescription {
        raw
        references {
          __typename
          ... on ContentfulArticle {
            contentful_id
            id
            legacyId
            title
            slug
            slug2
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
          ... on ContentfulCampaign {
            contentful_id
            name
            slug
          }
          ... on ContentfulDesk {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDeskCategory {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDownload {
            contentful_id
            id
            downloadType
            slug
            asset {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            formFields
            title
            sponsor {
              name
            }
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 690
                quality: 100
                breakpoints: [350, 690]
              )
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
          ... on ContentfulSponsor {
            contentful_id
            name
            url
          }
        }
      }

      introImage {
        title
        gatsbyImageData(layout: CONSTRAINED, quality: 100)
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
    }

    productList: allContentfulProductBeta(
      filter: { showOnOverview: { eq: true } }
    ) {
      nodes {
        showOnOverview
        slug
        contentful_id
        name
        excerpt {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        overviewButtonText
        overviewImage {
          title
          gatsbyImageData(layout: CONSTRAINED)
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        introDescription {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        excerpt {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        companyLogo {
          title
          gatsbyImageData(layout: CONSTRAINED)
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        introImage {
          title
          gatsbyImageData(
            layout: FIXED
            width: 200
            height: 200
            aspectRatio: 1
          )
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
